import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  getBinCollectionsForUPRNAsync,
  getCollectionLocationForUPRNAsync,
} from '../../services/bin-collections.service';
import BinCollectionComponent from '../../components/bin-collections/bin-collection.component';

import './bin-collections.container.scss';

const WASTE_SERVICE_NAME_REFUSE = 'REFUSE';

const BinCollectionsContainer = ({ uprn, calendarUrl }) => {
  const [binCollections, setBinCollections] = useState([]);
  const [binState, setBinState] = useState('NO DATA');
  const [binLocation, setBinLocation] = useState();

  // Fetching data from webaspx when urpn changes
  useEffect(() => {
    const binData = async () => {
      setBinState('LOADING');
      try {
        const result = await getBinCollectionsForUPRNAsync(uprn);
        const location = await getCollectionLocationForUPRNAsync(uprn);
        setBinCollections(result);
        setBinLocation(location);
        setBinState('LOADED');
      } catch (err) {
        setBinState('ERROR');
      }
    };
    binData();
  }, [uprn]);

  return (
    <>
      {binState === 'LOADING' && (
        <div className="network-loader cyc-box network-loader--loading">
          <i>Retrieving collection data...</i>
        </div>
      )}

      {binState === 'LOADED' && binCollections.length === 0 && (
        <div className="bin-collections-lookup__loader">
          <p>No available data</p>
          <p>We couldn't find any bin collection data for this property.</p>
        </div>
      )}

      {binState === 'LOADED' && binCollections.length > 0 && (
        <div>
          View your <Link to={`${calendarUrl}/${uprn}`}>waste and recycling calendar</Link> for the current year.
         
          <div className="bin-collections-lookup__services row">
            {binCollections.map((collection, key) => {
              // If it's Central Collection Point, add bin location information to household waste card
              const collectionClone = {
                ...collection,
                serviceCount: binCollections.length,
              };
              if (
                collection.service === WASTE_SERVICE_NAME_REFUSE &&
                binLocation &&
                (binLocation.isCentralCollection || binLocation.isCommunal)
              ) {
                collectionClone.wasteCollectedFrom = binLocation.collectionLocation;
              }
              return <BinCollectionComponent key={key} collection={collectionClone} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

BinCollectionsContainer.propTypes = {
  canlendarUrl: PropTypes.string,
  uprn: PropTypes.string,
};

export default BinCollectionsContainer;
